<template>
  <footer :class="s.wrap" v-if="isShowPage">
    <div :class="s.left">
      <div :class="s.links">
        <ul
          v-for="(items, k) in adjustedMenues"
          :key="k">
          <li v-for="item in items" :key="item.label">
            <a :href="`${msbsmUrls[helper.env.name]}${item.path}`">{{ item.label }}</a>
          </li>
        </ul>
      </div>
      <div class="pc">
        <a :href="msbsmUrls[helper.env.name]" target="_blank">
          <div :class="s.logo_wrap">
            <img src="/images/logo.png" alt="">
            <div :class="s.logo_text_wrap">
              <div :class="s.logo_text_sub">桐生市移住支援ポータルサイト</div>
              <div :class="s.logo_text">むすびすむ桐生</div>
            </div>
          </div>
        </a>
        <!-- <div :class="s.waiting">「むすびすむ桐生」掲載画像募集中</div> -->
      </div>
    </div>
    <div :class="s.right">
      <div :class="s.contact_title">お問い合わせ</div>
      <ul :class="s.contact">
        <li
          v-for="c in contacts"
          :key="c.name">
          <div :class="s.contact_label">{{ c.label }}</div>
          <div :class="s.contact_tel">{{ c.tel }}{{ c.tel_note || '' }}</div>
          <div :class="s.contact_other" class="n2br" v-html="c.other"/>
          <div :class="s.contact_email">{{ c.email }}</div>
          <div
            v-if="c.btn && helper.env.name"
            :class="s.contact_btn">
            <basic-btn
              :tag="'a'"
              :link="`${msbsmUrls[helper.env.name]}${c.btn.link}`"
              :size="'sm'"
              :width="'fit-content'">{{ c.btn.label }}</basic-btn>
          </div>
        </li>
      </ul>
    </div>
    <div class="sp">
      <div :class="s.logo_wrap">
        <img src="/images/logo.png" alt="">
        <div :class="s.logo_text_wrap">
          <div :class="s.logo_text_sub">桐生市移住支援ポータルサイト</div>
          <div :class="s.logo_text">むすびすむ</div>
        </div>
      </div>
      <div :class="s.waiting">「むすびすむ桐生」掲載画像募集中</div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex';
import {
  BasicBtn,
} from '@/components/parts';

export default {
  name: 'global-footer',
  components: { BasicBtn },
  data() {
    return {
      msbsmUrls: {
        local: 'http://localhost:3088',
        develop: 'https://dev.kiryu-iju.jp',
        production: 'https://kiryu-iju.jp',
      },
      items: [
        {
          label: '桐生を知る',
          path: '/about/',
        },
        {
          label: '移住支援・サポート体制',
          path: '/shien/',
        },
        {
          label: '店舗開業のススメ',
          path: '/kaigyou/',
        },
        {
          label: '桐生で働く・営む',
          path: '/hataraku/',
        },
        {
          label: '桐生に住む',
          path: '/sumu/',
        },
        {
          label: '桐生で育てる',
          path: '/kosodate/',
        },
        {
          label: 'インタビュー',
          path: '/interview/',
        },
      ],
      contacts: [
        {
          name: 'move',
          label: '移住に関する相談・お問い合わせ',
          tel: '050-3529-6573',
          other: '桐生市移住支援フロント むすびすむ桐生\n平日・土日祝日 10:00〜17:00　休業日：年末年始',
          email: 'musubisumu@kiryu-iju.jp',
          btn: {
            link: '/contact/',
            label: 'お問い合わせフォーム',
          },
        },
        {
          name: 'akiya',
          label: '空き家・空き地バンクに関するお問い合わせ',
          tel: '0277-48-9036',
          other: '桐生市 空き家対策室\n平日 8:30〜17:15　休業日：土日祝日・年末年始',
          email: 'akiya@city.kiryu.lg.jp',
        },
        {
          name: 'site',
          label: 'このサイトに関するお問い合わせ',
          tel: '0277-32-3812',
          other: '桐生市企画課移住定住推進室\n平日 8:30〜17:15　休業日：土日祝日・年末年始',
          email: 'ijuteiju@city.kiryu.lg.jp',
        },
      ],
    };
  },
  computed: {
    ...mapState(['helper']),
    adjustedMenues() {
      const menues = {
        left: [],
        right: [],
      };
      this.items.forEach((item, i) => {
        if (this.items.length / 2 >= i) menues.left.push(item);
        else menues.right.push(item);
      });
      return menues;
    },
    isShowPage() {
      return !this.$route.path.includes('print');
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  background-color: #ffffff;
  padding: 80px 120px;
  display: flex;
  align-items: stretch;
  height: var(--footer-height);
}

.left, .right {
  width: 50%;
}

.left {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.right {
  border-left: 1px solid var(--border-divider-gray);
  padding-left: 80px;
}

.links {
  display: flex;
  > ul {
    width: 50%;
    > li {
      &:not(:first-child) {
        margin-top: 32px;
      }
    }
    a {
      font-weight: 700;
      color: var(--label-primary);
    }
  }
}

.logo {
  &_wrap {
    display: flex;
    align-items: center;
    margin-top: 50px;
    color: var(--label-primary);
    img {
      width: 50px;
    }
  }
  &_text {
    &_wrap {
      margin-left: 12px;
      letter-spacing: 0.18em;
      line-height: 1;
    }
    &_sub {
      font-size: 12px;
      margin-bottom: 6px;
    }
    font-size: 24px;
  }
}

.contact {
  margin-top: 32px;
  > li {
    &:not(:first-child) {
      margin-top: 32px;
    }
  }
  &_title {
    font-size: 24px;
  }
  &_label {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 8px;
  }
  &_tel {
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    margin-bottom: 8px;
  }
  &_other {
    font-size: 12px;
  }
  &_email {
    font-size: 12px;
  }
  &_btn {
    margin-top: 12px;
  }
}

.waiting {
  font-size: 12px;
  margin-top: 20px;
  text-decoration: underline;
}

@include smView {
  .wrap {
    display: block;
    padding: 20px;
    height: var(--footer-height-sm);
    > * {
      width: 100%;
    }
  }

  .right {
    border-left: none;
    border-top: 1px solid ;
    margin-top: 48px;
    padding-top: 48px;
    padding-left: 0;
  }

  .links {
    display: block;
    > * {
      width: 100%;
      margin-right: 0;
      &:not(:first-child) {
        margin-top: 32px;
      }
    }
  }

  .logo_wrap {
    justify-content: center;
  }

  .waiting {
    text-align: center;
  }
}
</style>
